const hasItemSelected = (selected, rows) => selected.length && rows.length

export default (rows, selected) => {
  let hasCredit = false
  let hasSomeDispute = false
  let isComercialOrExecutive = false
  let isCustomerDispute = false
  if (hasItemSelected(selected, rows)) {
    rows.forEach((invoice) => {
      if (selected.includes(invoice.id)) {
        if(invoice.customerIsDispute) return (isCustomerDispute = true)
        if (invoice.isDispute) return (hasSomeDispute = true)
        if (Number(invoice.totalAmount) < 0) return (hasCredit = true)

        if (![30, 10].includes(invoice.distributionChannel))
          return (isComercialOrExecutive = true)
      }
    })
  }

  return !hasCredit && !isCustomerDispute && !hasSomeDispute && !isComercialOrExecutive
}
