import React, { useState } from 'react'
import {
  saveCustomerDispute,
} from '../../domain/account/customers-emails'

import Checkbox from '../../component/checkbox'

export default ({ customer, disabled, isDispute }) => {
  const [statusCheck, setStatusCheck] = useState(isDispute || false)

  const handleCheckboxJobs = async (change) => {
    await saveCustomerDispute(customer, change)
    setStatusCheck(change)
  }

  return (
    <Checkbox
      disabled={disabled}
      onChange={(onChange) => handleCheckboxJobs(onChange.currentTarget.checked)}
      checked={statusCheck}
    />
  )
}
