import React from 'react'
import TableBodyMUI from '../../../component/table/table-body'
import TableCell from '../../../component/table/table-cell'
import TableRow from '../../../component/table/table-row'
import isSelected from './is-selected'
import getCellStyles from './get-cell-styles'
import getOverdueClass from './get-overdue-class'
import getProductDescription from './get-product-description'
import PdfCell from './pdf-cell'
import fiscalNumberCell from './fiscal-number-cell'
import paidInCell from './paid-in-cell'
import customerCodeCell from './customer-code-cell'
import transactionNumberCell from './transaction-number-cell'
import clearingNumberCell from './clearing-number-cell'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import showCheckbox from './show-checkbox'
import DisputeIcon from './dispute-icon'
import CoreIcon from './core-icon'
import getNumberInvoices from './get-number-invoices'
import WireTransferCell from './wire-transfer-cell'
import { useTranslation } from 'react-i18next'
import DisputeCaseNumberCell from './dispute-case-number-cell'
import { isEmployee } from '../../../domain/security'

const TableBody = (props) => {
  const { t } = useTranslation()
  return (
    <TableBodyMUI>
      {orderBy(props.rows, props.orderBy, props.order).map((row, index) => {
        const isItemSelected = isSelected(row.id)(props.selected)
        const labelId = `enhanced-table-checkbox-${index}`
        const canSelect = props.hasCustomerSelected
        const product = getProductDescription(row.division, row.type)

        return (
          <TableRow
            hover
            onClick={() => {
              props.onClick(row.id, canSelect)
              props.addRow(row, canSelect)
            }}
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            className={getCellStyles(row, props.isClosed)}>
            {customerCodeCell({
              row,
              labelId,
              user: props.user,
              isClosed: props.isClosed
            })}
            <TableCell
              align='left'
              data-label-thead='Invoice'
              className={getCellStyles(row, props.isClosed)}>
              {row.number}
            </TableCell>
            <TableCell align='left' data-label-thead='Core Return'>
              {['E28'].includes(row.termsOfPayment) ? CoreIcon : null}
            </TableCell>
            <TableCell align='left' data-label-thead='Po Number'>
              {row.purchaseOrderNumber}
            </TableCell>
            {fiscalNumberCell(row, props.showFiscalNumber)}
            <TableCell align='left' data-label-thead='Billing Doc'>
              {getNumberInvoices(row)}
            </TableCell>
            <TableCell
              align='left'
              data-label-thead='Product'
              style={props.isClosed ? { display: 'none' } : {}}>
              {product}
            </TableCell>
            <TableCell align='left' data-label-thead='Issued Date'>
              {moment(row.issuedDate).format('YYYY-MM-DD')}
            </TableCell>
            <TableCell
              align='left'
              data-label-thead='Due Date'
              className={getOverdueClass(row, props.isClosed)}>
              {moment(row.dueDate).format('YYYY-MM-DD')}
            </TableCell>
            {paidInCell(row, props.isClosed)}
            {transactionNumberCell(row, props.isClosed)}
            {props.isClosed ? (
              <WireTransferCell
                row={row}
                inputSearch={props.inputSearch}
                leader={props.leader}
                camCsi={props.camCsi}
                customerGroup={props.customerGroup}
                customer={props.customer}
                csi={props.csi}
              />
            ) : null}
            <PdfCell row={row} />
            {isEmployee(props.user) ? (
              <TableCell align='left' data-label-thead='Case Owner'>
                {row.caseOwner ? row.caseOwner : ''}
              </TableCell>
            ) : null}
            <TableCell align='left' data-label-thead='Dispute Case #'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                {row.isDispute ? (
                  <DisputeCaseNumberCell caseNumber={row.caseNumber} />
                ) : null}
                {row.isDispute ? <DisputeIcon /> : null}
              </div>
            </TableCell>
            <TableCell align='left' data-label-thead='Dispute'>
              {row.isDispute ? row.disposition : ''}
            </TableCell>
            <TableCell align='left' data-label-thead='Total'>
              {numeral(row.totalAmount).format('0,0.00')}
            </TableCell>
            <TableCell align='left' data-label-thead='Installment'>
              {row.installment}
            </TableCell>
            {clearingNumberCell(row, props.isClosed, t)}
            <TableCell padding='checkbox' style={{ textAlign: 'right' }}>
              {showCheckbox({
                isItemSelected,
                labelId,
                canSelect,
                status: props.status
              })}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBodyMUI>
  )
}

export default TableBody
